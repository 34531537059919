import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UiKitLibModule } from 'ui-kit-lib';
import { LastPassHandlerDirective } from '../../../app-shared/directives/lastpass-fix.directive';

import { TranslationModule } from '../translate/translation.module';

import { BoxInfoComponent } from './components/box-info/box-info.component';
import { CreationButtonsComponent } from './components/creation-buttons/creation-buttons.component';
import { CartPartialOfferInfoComponent } from './components/header/cart-partial-offer-info/cart-partial-offer-info.component';
import { CartPartialPriceAndDeliveryInfoComponent } from './components/header/cart-partial-price-and-delivery-info/cart-partial-price-and-delivery-info.component';
import { CartTotalPriceComponent } from './components/header/cart-total-price/cart-total-price.component';

import { HeaderComponent } from './components/header/header.component';
import { InsertPromoCodeBlockComponent } from './components/insert-promo-code-block/insert-promo-code-block.component';
import { PromoBannerComponent } from './components/promo/promo-banner/promo-banner.component';
import { StorageFormComponent } from './components/storage-form/storage-form.component';
import { InferConsentTextPipe } from './pipes/infer-consent-text/infer-consent-text.pipe';
import { InferDataOnlyLabelPipe } from './pipes/infer-data-only-label/infer-data-only-label.pipe';
import { InferObjectKeysPipe } from './pipes/infer-object-keys/infer-object-keys.pipe';
import { InferOperatorsCartLinkMessagePipe } from './pipes/infer-operators-cart-link-message/infer-operators-cart-link-message.pipe';

import { DisplayInputButtonComponent } from './components/display-input-button/display-input-button.component';
import { InferCartItemsPipe } from './pipes/infer-cart-items/infer-cart-items.pipe';
import { InferCartPriceLabelsPipe } from './pipes/infer-cart-price-labels/infer-cart-price-labels.pipe';
import { InferCurrencyLabelPipe } from './pipes/infer-currency-label/infer-currency-label.pipe';
import { InferExclusiveDiscountPipe } from './pipes/infer-exclusive-discount/infer-exclusive-discount.pipe';
import { InferFreeLabelPipe } from './pipes/infer-free-label/infer-free-label.pipe';
import { InferFrequencyLabelPipe } from './pipes/infer-frequency-label/infer-frequency-label.pipe';
import { InferGigax2Pipe } from './pipes/infer-gigax2/infer-gigax2.pipe';
import { InferOperatorsCartListPipe } from './pipes/infer-operators-cart-list/infer-operators-cart-list.pipe';
import { InferOperatorsCartMessagePipe } from './pipes/infer-operators-cart-message/infer-operators-cart-message.pipe';
import { InferOperatorsPipe } from './pipes/infer-operators/infer-operators.pipe';
import { InferPriceSplitPipe } from './pipes/infer-price-split/infer-price-split.pipe';
import { InferRepetitionsLabelPipe } from './pipes/infer-repetitions-label/infer-repetitions-label.pipe';
import { InferUnlimitedLabelPipe } from './pipes/infer-unlimited-label/infer-unlimited-label.pipe';
import { NotEmptyCollectionPipe } from './pipes/not-empty-collection/not-empty-collection.pipe';
import { ShouldBeVisiblePaymentTypePipe } from './pipes/should-be-visible-payment-type/should-be-visible-payment-type.pipe';

@NgModule({
  declarations: [
    BoxInfoComponent,
    CartPartialOfferInfoComponent,
    CartPartialPriceAndDeliveryInfoComponent,
    CreationButtonsComponent,
    HeaderComponent,
    InferCartItemsPipe,
    InferConsentTextPipe,
    InferObjectKeysPipe,
    NotEmptyCollectionPipe,
    ShouldBeVisiblePaymentTypePipe,
    CartTotalPriceComponent,
    InferCartPriceLabelsPipe,
    InferCurrencyLabelPipe,
    InferDataOnlyLabelPipe,
    InferFreeLabelPipe,
    InferFrequencyLabelPipe,
    InferOperatorsCartLinkMessagePipe,
    InferOperatorsCartMessagePipe,
    InferOperatorsPipe,
    InferUnlimitedLabelPipe,
    InferOperatorsCartListPipe,
    StorageFormComponent,
    PromoBannerComponent,
    InsertPromoCodeBlockComponent,
    InferGigax2Pipe,
    InferExclusiveDiscountPipe,
    DisplayInputButtonComponent,
    InferPriceSplitPipe,
    InferRepetitionsLabelPipe,
    LastPassHandlerDirective
  ],
  imports: [
    CommonModule,
    UiKitLibModule,
    TranslationModule
  ],
    exports: [
        BoxInfoComponent,
        CartPartialOfferInfoComponent,
        CartPartialPriceAndDeliveryInfoComponent,
        CreationButtonsComponent,
        HeaderComponent,
        InferCartItemsPipe,
        InferConsentTextPipe,
        InferCurrencyLabelPipe,
        InferDataOnlyLabelPipe,
        InferObjectKeysPipe,
        NotEmptyCollectionPipe,
        ShouldBeVisiblePaymentTypePipe,
        StorageFormComponent,
        PromoBannerComponent,
        InsertPromoCodeBlockComponent,
        InferCartPriceLabelsPipe,
        InferPriceSplitPipe,
        InferFrequencyLabelPipe,
        LastPassHandlerDirective
    ]
})
export class SharedModule {}
