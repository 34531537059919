<div class="sb-cart-partial-offer-info">
  <div class=" sb-cart-partial-offer-info__title wt-text-base-l">
    <div class="sb-cart-partial-offer-info__title-container">
      <span>{{cartAnalysis | inferOperatorsCartMessage:operators | translate}} </span>
      <a class="sb-cart-partial-offer-info--link"
          *ngIf="shouldShowToggle"
          (click)="toggle()"
          [ngClass]="shouldShowOperators ? 'close-operators' : ''">{{cartAnalysis | inferOperatorsCartLinkMessage:operators | translate}}<i aria-hidden="true" class="close-operators-icon"></i></a>
          <div class="wt-text-align-center wt-text-base-s sb-cart-partial-offer-info__title other-operators"
               *ngIf="shouldShowOperators"
               @fadeAnimation
               [innerHTML]="cartAnalysis | inferOperatorsCartList:operators"></div>
    </div>
  </div>
  <div class="sb-cart-partial-offer-info__details" *ngIf="currentOffer.offerType !== 'data-only' && !shouldShowGigax2">
    <div class="sb-cart-partial-offer-info__gb-barrati" *ngIf="hasDoublePO">{{currentOffer.previousGb}}</div>
    <div class="sb-cart-partial-offer-info__offer" [innerHTML]="currentOffer.gb | inferUnlimitedLabel:currentOffer.minutes:currentOffer.sms"></div>
<!--    <div class="sb-cart-partial-offer-info__further-info">-->
<!--      <div class="sb-cart-partial-offer-info__further-info__box" [innerHTML]="'ENTITY.CART.4G.TEXT' | translate"></div>-->
<!--    </div>-->
  </div>
  <div class="sb-cart-partial-offer-info__details" *ngIf="currentOffer.offerType === 'data-only' && !shouldShowGigax2">
    <div class="sb-cart-partial-offer-info__offer" [innerHTML]="currentOffer.gb | inferDataOnlyLabel:currentOffer.minutes:currentOffer.sms:currentOffer.productId"></div>
  </div>
  <div class="sb-cart-partial-offer-info__details" *ngIf="shouldShowGigax2">
    <div class="sb-cart-partial-offer-info__offer" [innerHTML]="currentOffer.gb | inferGigax2:currentOffer.minutes:currentOffer.sms:currentOffer.offerType"></div>
<!--    <div class="sb-cart-partial-offer-info__further-info">-->
<!--      <div class="sb-cart-partial-offer-info__further-info__box" [innerHTML]="'ENTITY.CART.4G.TEXT' | translate"></div>-->
<!--    </div>-->
  </div>
  <sb-promo-banner [isInCart]="true"></sb-promo-banner>
</div>
