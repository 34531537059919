import { AfterViewInit, Directive, ElementRef, Renderer2 } from '@angular/core';

@Directive({
    selector: '[lastPassHandler]'
})
export class LastPassHandlerDirective implements AfterViewInit {
    constructor(private el: ElementRef, private renderer: Renderer2) {}

    ngAfterViewInit() {
        const inputElement = this.el.nativeElement.querySelector('input');
        if (!inputElement) {
            return;
        }

        const hiddenInput = this.createHiddenInput(inputElement);
        const wrapper = this.createHiddenWrapper(hiddenInput);
        const parent = inputElement.parentNode;
        this.renderer.insertBefore(parent, wrapper, inputElement);
        this.preventLastPassInterference(inputElement);
        this.setupEventHandlers(inputElement, hiddenInput);
    }

    private createHiddenInput(originalInput: HTMLInputElement): HTMLInputElement {
        const hiddenInput = this.renderer.createElement('input');
        const controlName = originalInput.getAttribute('formControlName');

        this.renderer.setAttribute(hiddenInput, 'type', 'text');
        this.renderer.setAttribute(hiddenInput, 'style', 'display:none');

        switch (controlName) {
            case 'firstName':
                this.setInputAttributes(hiddenInput, {
                    'data-form-type': 'firstname',
                    name: 'firstName',
                    autocomplete: 'nome'
                });
                break;
            case 'lastName':
                this.setInputAttributes(hiddenInput, {
                    'data-form-type': 'lastname',
                    name: 'lastName',
                    autocomplete: 'cognome'
                });
                break;
            case 'address':
                this.setInputAttributes(hiddenInput, {
                    'data-form-type': 'street',
                    name: 'street',
                    autocomplete: 'indirizzo', // da verificare
                    'data-lastpass-field-type': 'indirizzo'
                });
                break;
            case 'zipCode':
                this.setInputAttributes(hiddenInput, {
                    'data-form-type': 'zipCode',
                    name: 'zipCode',
                    autocomplete: 'cap',
                    inputmode: 'numeric',
                    'data-lastpass-field-type': 'cap'
                });
                break;
            case 'birthDate':
                this.setInputAttributes(hiddenInput, {
                    'data-form-type': 'birthDate',
                    name: 'birthDate',
                    autocomplete: 'birthdate',
                    'data-lastpass-field-type': 'birthdate'
                });
                break;
        }

        return hiddenInput;
    }

    private setInputAttributes(input: HTMLInputElement, attributes: { [key: string]: string }): void {
        Object.entries(attributes).forEach(([key, value]) => {
            this.renderer.setAttribute(input, key, value);
        });
    }

    private createHiddenWrapper(hiddenInput: HTMLInputElement): HTMLElement {
        const wrapper = this.renderer.createElement('div');
        this.renderer.setStyle(wrapper, 'height', '0');
        this.renderer.setStyle(wrapper, 'width', '0');
        this.renderer.setStyle(wrapper, 'position', 'absolute');
        this.renderer.setStyle(wrapper, 'overflow', 'hidden');
        this.renderer.appendChild(wrapper, hiddenInput);
        return wrapper;
    }

    private preventLastPassInterference(input: HTMLInputElement): void {
        const randomSuffix = `_${Math.random().toString(36).substr(2, 9)}`;
        this.renderer.setAttribute(input, 'data-lpignore', 'true');
        this.renderer.setAttribute(input, 'autocomplete', 'off');
        this.renderer.setAttribute(input, 'name', `field${randomSuffix}`);
    }

    private setupEventHandlers(originalInput: HTMLInputElement, hiddenInput: HTMLInputElement): void {
        this.renderer.listen(hiddenInput, 'input', (event) => {
            const value = event.target.value;
            this.updateInputValue(originalInput, value);
        });

        this.renderer.listen(hiddenInput, 'change', (event) => {
            const value = event.target.value;
            this.updateInputValue(originalInput, value);
        });

        const inputWrapper = this.el.nativeElement.querySelector('.wt-input');
        if (inputWrapper) {
            this.renderer.listen(originalInput, 'focus', () => {
                this.renderer.addClass(inputWrapper, 'wt-input--focused');
            });

            this.renderer.listen(originalInput, 'blur', () => {
                if (!originalInput.value) {
                    this.renderer.removeClass(inputWrapper, 'wt-input--focused');
                }
            });
        }
    }

    private updateInputValue(input: HTMLInputElement, value: string): void {
        this.renderer.setProperty(input, 'value', value);

        ['input', 'change'].forEach(eventType => {
            const event = new Event(eventType, { bubbles: true });
            input.dispatchEvent(event);
        });

        const wrapper = this.el.nativeElement.querySelector('.wt-input');
        if (wrapper) {
            if (value) {
                this.renderer.addClass(wrapper, 'wt-input--focused');
            } else {
                this.renderer.removeClass(wrapper, 'wt-input--focused');
            }
        }
    }
}
